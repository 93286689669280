// import React from "react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser, setCurrentUser } from "../../actions/User.action";
import { setAppSettings } from "../../actions/AppSettings.action";
import { leaveRoom } from "../../actions/Room.action";
import Icon from "../others/Icon.component";
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';
import UserIcon from '../../images/user-icon.png';
import axios from "axios";
import imageEXIF from "../others/imageEXIF.component";
import ImageCutter from "../others/ImageCutter.component";

const isEmpty = require('is-empty');

let url = window.location.origin;
if (process.env.REACT_APP_ENV === "local")
    url = process.env.REACT_APP_API_URL;

class Sidebar extends Component {
  constructor(props){
    super(props);
    this.openNav = this.openNav.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    // this.flowersLink = this.flowersLink.bind(this);
    this.navbarStyle = this.navbarStyle.bind(this);
    this.inputEditing = this.inputEditing.bind(this);
    this.inputStyle = this.inputStyle.bind(this);
    this.state = {
      name:'',
      editing_name:false,
      image:'',
      image_preview:'',
      profile_src:''
    };
  }
  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name] : value
    });
  }
  handleChangeFile = async event => {
    const file = event.target.files[0];
    if (isEmpty(file))
      return 0;
    const result = await imageEXIF.getImageUrl(file);
    this.setState({image_preview:result,image: file});
    //this.onSubmit();
    new ImageCutter(result, 400,(rslt)=>{
      // console.log(rslt);
      this.setState({image_preview:rslt.base64,image: rslt.blob});
      this.onSubmit();
    });
  }
  componentDidMount(){
    let state = {
      name:this.props.user.name
    }
    if(!isEmpty(this.props.app_settings.user_pic_url)){
        state.profile_src = this.props.app_settings.user_pic_url;

    }else if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id)){
        state.profile_src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?" + new Date().getTime();
        // let src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?" + new Date().getTime();
        // this.props.setAppSettings({user_pic_url:src});
    }
   
    
    this.setState(state);
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();

    //logout according login api. detailed to de server on login, the server 
    //add to the login user info and the redux state save it on user.api_platform prop
    if (!isEmpty(this.props.user.api_platform)) {
      if (this.props.user.api_platform === 'google') {
        if (!isEmpty(window.gapi)) {
          window.gapi.load('auth2', function() {
            let auth2 = window.gapi.auth2.init();
            auth2.signOut().then(function () {
              localStorage.removeItem('agreed');
              window.location.reload();
            });
          });
        }else{
          console.log('No se pudo conectar a google. Comprueba tu conexión.');
          alert('No se pudo conectar a google. Comprueba tu conexión.');
        }
      }else if (this.props.user.api_platform === 'facebook') {
        localStorage.removeItem('agreed');
        this.props.history.push("/login");
      }
    }else{
        localStorage.removeItem('agreed');
        window.location.pathname ="/";
        // this.props.history.push("/login");
    }
  };
  openNav() {
    this.props.setState({
      sidenav:!this.props.show
    })
  }
  outButton(){
    let button = null;
    if (isEmpty(this.props.user)) {
      button = [
            <button key={"register"} onClick={()=>{$("#login-modal").modal("show")}} className="btn btn-sidebar">
              Registrarme
            </button>,
            <button key={"out"} onClick={this.leaveRoom} className="btn btn-sidebar text-nowrap">
              Salir  de la sala
            </button>
      ];
    }else{
      button = <button onClick={this.onLogoutClick} className="btn btn-sidebar">
              Salir
            </button>;
    }
    return button;
  }
  leaveRoom(){
    this.props.leaveRoom();
    // this.props.history.push('/code');
    window.location.pathname ="/code";
  }
  // flowersLink(){
  //   const style = window.location.pathname==="/room/flowers"?{backgroundColor:'var(--gray)', color:'var(--light)'}:null;
  //     let params = "";
  //     if (this.props.app_settings.flower_url === "https://floristeria.eternify.es")
  //       params = "?code="+encodeURI(this.props.room.id)+"&billing_funeral_name="+encodeURI(this.props.room.name)+"&billing_funeral_parlor="+encodeURI(this.props.room.funeral_parlor.name)+"&billing_room="+encodeURI(this.props.room.room);
  //     return(<a href={this.props.app_settings.flower_url+params} rel="external nofollow" className="text-nowrap" style={style}
  //       onClick={()=>{
  //                   // window.gtag('event', 'click', {
  //                   //   'event_category': 'app',
  //                   //   'event_label': 'Botón Enviar Flores Menú',
  //                   //   // 'value': <value>
  //                   // });
  //       }}
  //     >
  //       Imprimir Libro
  //     </a>);
  // }
  navbarStyle(){
    if (!isEmpty(this.props.app_settings) || !isEmpty(this.props.app_settings.app_color)) {
      return JSON.parse('{"backgroundColor":"'+this.props.app_settings.app_color+'"}');
    }else{
      return {};
    }
  }
  inputEditing(prop){
    let state = JSON.parse('{'+
      '"editing_name":false'+
    '}');
    state[prop] = true;
    let focus = ()=>{
      let target = '';
      let elm = 'textarea';
      if(this.state.editing_room)
        target = 'name';
      if(target)
        document.querySelector(elm+'[name="'+target+'"]').focus();
    };
    this.setState(state,focus);
  }
  inputStyle(prop, element){
    // if (!this.state.im_admin)
    //     return 0;
    if (element === 'text') {
      if (this.state[prop]) {
        return JSON.parse('{"display":"none"}');
      }else{
        return JSON.parse('{"minHeight":"2rem", "marginBottom":".5rem"}');
      }
    }else{
      if (!this.state[prop]) {
        return JSON.parse('{"display":"none"}');
      }else{
        return JSON.parse('{"minHeight":"2rem", "marginBottom":".5rem"}');
      }
    }
  }
  profilePhoto(){
    let img = UserIcon;
    if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id)){
      img = this.state.profile_src;
    }else if (!isEmpty(this.props.user) && isEmpty(this.state.image_preview)) {
      return <div className="profile-letter" style={{backgroundColor:'var(--first-color)',width:'5rem',height:'5rem',fontSize:'3rem'}}>{this.props.user.name.charAt(0)}</div>
    }
    if (!isEmpty(this.state.image_preview))
      img = this.state.image_preview;
    return  <div className="profile-pic" style={{backgroundImage:'url('+img+')'}}></div>;
    // return <img src={img} className="img-fluid m-1 img-circle img-profile" alt=""/>;
  }
  onSubmit(prop = "") {
      let state = JSON.parse('{'+
        '"editing_name":false'+
      '}');
      this.setState(state);
      var formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("user_id", this.props.user.id);
      if (this.state.image)
        formData.append("image", this.state.image);
      const this_ = this;
      axios.post(url+'/api/web-user/update', formData, {
            "headers": {
              "Content-Type": "application/x-www-form-urlencoded",
              'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
            }
      }).then(function(res){
          console.log('SUCCESS!!',res);
          // let user = this_.props.user;
          // user.name = res.data.user.name;
          localStorage.user = JSON.stringify(res.data.user);
          this_.props.setCurrentUser({user:res.data.user});
          // console.log(this.)
          // if (!isEmpty(this_.props.updateRoom))
            
            //if (typeof this_.props.updateRoom !== 'undefined')
            let src = url+"/api/web-user/"+res.data.user.web_user.id+"/photo?a" + new Date().getTime();
            this_.setState({profile_src: src});
            this_.props.setAppSettings({app_settings:{user_pic_url:src}});
            if(typeof this_.props.updateRoom === 'function')
              this_.props.updateRoom(false);
          return 0;
      })

  }
  render() {
    return ([
      <Icon key='siderbar-open-icon' class="open-btn icon icon-menu" onClick={this.openNav}/>,
      <div key='siderbar' id="mySidenav" className={this.props.show?'sidenav sidenav-opn':'sidenav'}>
        {(()=>{
          if(!isEmpty(this.props.app_settings.user_pic_url) && this.state.profile_src !== this.props.app_settings.user_pic_url){
            this.setState({profile_src:this.props.app_settings.user_pic_url});
          }
        })()}
        <Icon class="closebtn icon icon-close"  onClick={this.openNav}/>
        <div>
          {(()=>{
            if (!isEmpty(this.props.user)) {
              return[
                <hr key="sidenav-hr" className="line-light"/>,
                <div key="sidenav-div" className="row m-0" style={{width:'300px'}}>
                  <div className="col-4">
                    <label htmlFor="image">
                      {this.profilePhoto()}
                    </label>
                    <input type="file" className="form-control-file d-none" id="image" name="image" accept="image/*"
                        onChange={this.handleChangeFile}
                    />
                  </div>
                  <div className="col-8 d-flex align-items-center">
                    <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_name')} style={Object.assign({top:'-5%',left:'70%'},this.inputStyle('editing_name', 'text'))}></label>
                    <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_name')} style={Object.assign({top:'-5%',left:'70%'},this.inputStyle('editing_name', 'input'))}></label>
                    <h4 style={Object.assign({width:'90%'},this.inputStyle('editing_name', 'input'))}>
                      <textarea
                        name="name"
                        className="w-100 text-secondary bg-light border-0 input-app"
                        style={{fontWeight:'inherit',maxHeight:'150px'}}
                        value={this.state.name}
                        onChange={this.handleChange}
                        required
                        maxLength="27"
                        onBlur={()=>this.onSubmit('editing_name')}
                      />
                    </h4>
                    <h4 
                      onClick={()=>this.inputEditing('editing_name')}
                      style={Object.assign({width:'90%', color:'var(--first-color)',wordBreak:'break-word'},this.inputStyle('editing_name', 'text'))}
                    >{isEmpty(this.state.name)?'':this.state.name}</h4>
                  </div>
                </div>
              ];
            }
          })()}
            <hr className="line-light"/>
            <Link to="/room/phone" className={"text-nowrap "+(window.location.pathname==="/room/phone"?"sidenav-selected":"")}>Inicio</Link>
            <Link to="/room/candle" className={"text-nowrap "+(window.location.pathname==="/room/candle"?"sidenav-selected":"")}>Globos</Link>
            <hr className="line-light"/>
            
            {/*<Link to="/room/info" className={"text-nowrap "+(window.location.pathname==="/room/info"?"sidenav-selected":"")}>Info del Evento</Link>*/}
            <Link to="/room/gallery" className={"text-nowrap "+(window.location.pathname==="/room/gallery"?"sidenav-selected":"")}>Galería de Imágenes</Link>
            <hr className="line-light"/>
            <Link to="#!" 
              className="text-nowrap"
              onClick={(e)=>{
                e.preventDefault();
                if (window.location.pathname !== '/room/gallery') {
                  localStorage.setItem('before_action', 'buy');
                  this.props.history.push('/room/gallery');
                }else{
                  if (!isEmpty($("#buy"))) {
                    $("#buy").click();
                  }
                }
              }}
            >
              Descargar Libro
            </Link>
            <Link to="#!" 
              className="text-nowrap"
              onClick={(e)=>{
                e.preventDefault();
                if (window.location.pathname !== '/room/gallery') {
                  localStorage.setItem('before_action', 'buy');
                  this.props.history.push('/room/gallery');
                }else{
                  if (!isEmpty($("#buy"))) {
                    $("#buy").click();
                  }
                }
              }}
            >
              Imprimir Libro
            </Link>
            {/*this.flowersLink()*/}
            <hr className="line-light"/>
            {(()=>{
                // if (!isEmpty(this.props.user) && this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0) {
                //   return [<Link key="link-sidebar" to="/room/info" className={"text-nowrap "+(window.location.pathname==="/room/info"?"sidenav-selected":"")}>Editar</Link>,
                //           <hr key="hr-sidebar" className="line-light"/>];
                // }
              })()}
            
            {this.outButton()}
            <Link to="/legal/document" style={{lineHeight:'normal',fontSize:'1rem',whiteSpace:'nowrap'}}>Términos, condiciones y políticas.</Link>

            <span style={{ 
                          position: 'absolute',
                          top: '100%',
                          left: '100%',
                          padding: '0.3rem',
                          transform: 'translate(-100%, -100%)',
                          color: 'var(--secondary)',
                          whiteSpace: 'nowrap'
                        }}
            >
              {process.env.REACT_APP_APP_VERSION}
            </span>
        </div>
      </div>
    ]);
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  leaveRoom: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setAppSettings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  app_settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  app_settings: state.app_settings
});

export default connect(
  mapStateToProps,
  { logoutUser, leaveRoom, setCurrentUser, setAppSettings }
)(Sidebar);