import {
	FLUSH_ERRORS,
	GET_ERRORS,
} from "./types";

export const flushError = () => dispatch => {
	dispatch({
		type: FLUSH_ERRORS,
		payload: {}
	})
};
export const getErrors = (res) => dispatch => {
	dispatch({
				type: GET_ERRORS,
				payload: res.data
			})
};
