import {
	SET_APP_SETTINGS
} from "./types";

// Set app settings
export const setAppSettings = payload => {
	return {
		type: SET_APP_SETTINGS,
		payload: payload
	};
};