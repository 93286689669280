import React from "react";
import ReactDOM from 'react-dom';

export default class ImageCutter {
  constructor(image_src, side, callback = ()=>{}){
    this.callback = callback;
    this.addToBody();
    this.image = new Image();
    this.image.crossOrigin = "Anonymous";
    this.image.onload = (e)=>{this.refreshImageVars()};
    this.image.src = image_src;
    this.image_base64 = null;
    this.image_blob = null;
    this.canvas_api = document.createElement('canvas');
    this.scroll = document.querySelector("#ic-scroll");
    this.cutter = document.querySelector("#ic-cutter");

    const min_screen = Math.min(window.screen.width,window.screen.height);
    const max_screen = 400;
    this.diameter = max_screen<min_screen?max_screen:min_screen;//square size or radius for cutter surface scrolling
    this.scroll.style.width = (this.diameter+5)+'px';
    this.scroll.style.height = (this.diameter+5)+'px';

    this.result_side = side;//square size of resulting image

    this.zoom = 1;
    this.max_zoom = 5;
    this.min_zoom = 1;
    this.radius = (this.diameter / 2);

    this.circle = {
      radius:0,
      x:0,
      y:0
    }
    this.square = {
      x:0,
      y:0,
      side:0
    }

    this.circle.radius = this.radius;
    this.circle.x = this.radius;
    this.circle.y = this.radius;

    this.square.side = this.diameter;

    this.src_width = this.image.width;
    this.src_height = this.image.height;
    this.ratio = this.src_height/this.src_width;
    this.out_width = 0;
    this.out_height = 0;
    this.result_image_width = 0;
    this.result_image_height = 0;
  }
  refreshImageVars(){
    this.src_width = this.image.width;
    this.src_height = this.image.height;
    this.ratio = this.src_height/this.src_width;
    this.out_width = 0;
    this.out_height = 0;

    
    if(this.src_width > this.src_height){
      this.out_height = this.square.side;
      this.out_width = parseFloat((this.out_height/this.ratio).toFixed(2));
      this.result_image_height = this.result_side;
      this.result_image_width = parseFloat((this.result_image_height/this.ratio).toFixed(2));
    }else{
      this.out_width = this.square.side;
      this.out_height = parseFloat((this.ratio*this.out_width).toFixed(2));
      this.result_image_width = this.result_side;
      this.result_image_height = parseFloat((this.ratio*this.result_image_width).toFixed(2));
    }
    this.draw();
  }
  drawCutter() {
    this.cutter.width = this.out_width*this.zoom;
    this.cutter.height = this.out_height*this.zoom;
    const cutter_ctx = this.cutter.getContext("2d");
    cutter_ctx.filter = 'blur(3px)';
    cutter_ctx.drawImage(this.image, 0, 0, this.image.width, this.image.height,0,0,this.out_width*this.zoom,this.out_height*this.zoom);
    cutter_ctx.filter = 'blur(0)';
    cutter_ctx.arc(this.circle.x, this.circle.y, this.circle.radius, 0, 2 * Math.PI);
    cutter_ctx.clip();
    cutter_ctx.drawImage(this.image, 0, 0, this.image.width, this.image.height,0,0,this.out_width*this.zoom,this.out_height*this.zoom);
    cutter_ctx.strokeStyle = 'rgba(255,255,255,0.5)';
    cutter_ctx.lineWidth = 0;
    cutter_ctx.stroke();
  }
  getImage() {
    this.canvas_api.width = this.result_side;
    this.canvas_api.height = this.result_side;
    const rslt_ctx = this.canvas_api.getContext("2d");
    
    rslt_ctx.drawImage(this.image, this.square.x*(this.src_width/this.out_width)/this.zoom, this.square.y*(this.src_height/this.out_height)/this.zoom, this.image.width, this.image.height,0,0,this.result_image_width*this.zoom,this.result_image_height*this.zoom);
    this.image_base64 = this.canvas_api.toDataURL();
    
  }
  async ok(){
    await this.canvas_api.toBlob(blob=>{
      this.callback({base64:this.image_base64,blob:blob});
      this.removeFromBody();
    });
  }
  draw() {
    this.drawCutter();
    this.getImage();
  }
  scrollCtrl(event){
    const target = event.target;
    this.circle.y = this.circle.radius + target.scrollTop;
    this.circle.x = this.circle.radius + target.scrollLeft;
    this.square.y = target.scrollTop;
    this.square.x = target.scrollLeft;
    this.draw();
  }
  zoomCtrl(value){
    this.refreshImageVars();
    const height_before = this.out_height*this.zoom;
    const width_before = this.out_width*this.zoom;
    if(this.zoom <= this.max_zoom && this.zoom >= this.min_zoom)
      this.zoom += parseFloat(value);
    if(this.zoom > this.max_zoom)
      this.zoom = this.max_zoom;
    if(this.zoom < this.min_zoom)
      this.zoom = this.min_zoom;
    const height_after = this.out_height*this.zoom;
    const width_after = this.out_width*this.zoom;
    //centering zoom
    this.scroll.scrollTop += (height_after-height_before)*0.5;
    this.scroll.scrollLeft += (width_after-width_before)*0.5;
    
    this.draw();
  }
  addToBody(){
    let div = document.querySelector("#div-tmp");
    if (!div) {
      div = document.createElement('div');
      div.id = "div-tmp";
      document.body.appendChild(div);
      div = document.querySelector("#div-tmp");
    }
    ReactDOM.hydrate(this.render(), document.querySelector("#div-tmp"));
  }
  removeFromBody(){
    let div = document.querySelector("#div-tmp");
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      div.remove();
    }
  }
  render() {
    return (
        <div className="ic-background">
          <div id="ic-scroll" className="ic-scroll" onScroll={event=>{this.scrollCtrl(event)}}>
            <canvas id="ic-cutter"></canvas>
            <div className="ic-buttons">
              <span className="ic-button" style={{left:"5%"}} onClick={()=>{this.zoomCtrl(0.1)}}>+</span>
              <span className="ic-button" style={{left:"15%"}} onClick={()=>{this.zoomCtrl(-0.1)}}>-</span>
              <span className="ic-button" style={{left:"25%", transform:'rotateZ(45deg)'}} onClick={this.removeFromBody}>+</span>
              <span className="ic-button" style={{left:"35%",fontSize:'inherit'}} onClick={()=>{this.ok()}}>ok</span>
            </div>
          </div>
        </div>
      );
  }
}
