import React from "react";
import $ from "jquery";
import AddContactIcon from '../../images/add-contact.svg';

const isEmpty = require('is-empty');

function ShareButton(props, setLoginModal, beforeAction){
  const icon = <img src={AddContactIcon} className="navbar-icon" alt="" style={{width:'2.4rem'}}/>;
  if (navigator.share){
    return <a 
                href="#!"
                id="share"
                onClick={(event)=>{
                    event.preventDefault();
                    // window.gtag('event', 'click', {
                    //     'event_category': 'app',
                    //     'event_label': 'Botón Comunicar Allegados Sala',
                    //     // 'value': <value>
                    // });
                    if (isEmpty(props.user)) {
                      $("#login-modal").modal("show");
                      setLoginModal("show");
                      beforeAction("share");
                      return 0;
                    }
                    activeShareAPI(props);
                  }}
                >
                {icon}
            </a>
  }else{
    return <a 
				id="share"
				href={shareLink(props)}
				onClick={(event)=>{
					if (isEmpty(props.user)) {
						event.preventDefault();
						$("#login-modal").modal("show");
						setLoginModal("show");
            beforeAction("share");
						
					}
					// window.gtag('event', 'click', {
					// 	  'event_category': 'app',
					// 	  'event_label': 'Botón Comunicar Allegados Sala',
					// 	  // 'value': <value>
					// });
				}}
			>
        {icon}
			</a>
    }
}

function activeShareAPI(props){
  const shareData = {
    title: window.location.host,
    text: '¡Únete a mi fiesta de Cumpleaños!\nEs el cumpleaños de '+props.room.name+'. Desde este enlace podrás unirte al cumpleaños, escribirle una felicitación y compartir imágenes.\n ¡Te esperamos!',
    url: "https://"+window.location.host+"/code/"+props.room.id,
  };
  navigator.share(shareData);
}

function shareLink(props){
    return(
      "https://api.whatsapp.com/send?text="+
      encodeURI(
        '¡Únete a mi fiesta de Cumpleaños!\nEs el cumpleaños de '+props.room.name+'. Desde este enlace podrás unirte al cumpleaños, escribirle una felicitación y compartir imágenes.\n ¡Te esperamos!\n'+
        "https://"+window.location.host+"/code/"+props.room.id
      )
    );
}
export default {ShareButton};