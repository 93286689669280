import {SET_CURRENT_USER} from "../actions/types";
const isEmpty = require('is-empty');
const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return isEmpty(action.payload)?action.payload:action.payload.user;
		default:
			return state;
	}
}