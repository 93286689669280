import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';
const isEmpty = require('is-empty');

class Legal extends Component {
	validate(event){
		var form = document.getElementById('agree-form');
		// Loop over them and prevent submission
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
		    
		}else{
			localStorage.agreed = true;
			$('#exampleModal').modal('hide');
		}
		form.classList.add('was-validated');
	}
	componentDidMount(){
		if (
			(isEmpty(localStorage.agreed) || localStorage.agreed === "false") 
			&& window.location.pathname !== "/legal/document"
		) {
			$('#exampleModal').modal('show');
		}
	}
	render() {
	    return(
	    	<div className="modal fade" id="exampleModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:2001}}>
			  <div className="modal-dialog modal-dialog-centered" role="document">
			    <div className="modal-content">
			      <div className="modal-header text-center">
			        <h5 className="modal-title text-info" id="exampleModalLabel">INFORMACIÓN BÁSICA SOBRE LA POL&Iacute;TICA DE PRIVACIDAD</h5>
			      </div>
			      <form className="needs-validation" id="agree-form" noValidate>
			      <div className="modal-body py-0 px-1">
			        <div className="p-2" style={{maxHeight:'25vh',overflowY:'scroll',overflowX:'hidden'}}>
						<iframe src={process.env.REACT_APP_API_URL+"/api/conditions-brief"}
				    		title="Condiciones"
				    		height="90%"
				    		width="100%"
				    		style={{border:'0'}}
				    	></iframe>
			        </div>
			        <p className="text-justify m-2">
						<span>Antes de prestar su consentimiento, debe proceder a la lectura completa del </span><span>Aviso Legal, Pol&iacute;tica de Privacidad y Condiciones de uso. </span><span>Para una informaci&oacute;n m&aacute;s completa, </span><span><a href="/legal/document">pinche aquí.</a></span>
					</p>
			      	<div className="custom-control custom-checkbox m-2">
						
					  	<input type="checkbox" className="custom-control-input" id="customCheck1" required/>
					  	<label className="custom-control-label" htmlFor="customCheck1">
					  		Acepto las condiciones de uso
					  	</label>
						<div className="invalid-feedback">
				          Debe aceptar las condiciones de uso
				        </div>
					</div>
			      </div>
			      <div className="modal-footer">
			        <button onClick={(e)=>{e.preventDefault();window.location.href="https://eternify.es";}} className="btn btn-danger w-50 btn-et">Rechazar</button>
			        <button type="submit" onClick={this.validate} className="btn btn-primary w-50 btn-et">Continuar</button>
			      </div>
			      </form>
			    </div>
			  </div>
			</div>
	    );
	}
}

Legal.propTypes = {
  app_settings: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  app_settings: state.app_settings,
});
export default connect(
  mapStateToProps,
  {}
)(Legal);