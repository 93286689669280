import store from "../../store";
import { setAppSettings } from "../../actions/AppSettings.action";
const isEmpty = require('is-empty');

function load() {
	if (!isEmpty(localStorage.app_settings)) {
		const app_settings = JSON.parse(localStorage.app_settings);
		store.dispatch(setAppSettings({app_settings:app_settings}));
	}
}
function checkRoom() {
	if (isEmpty(localStorage.room)) {
		return false;//not ok
	}
	if ( isEmpty(JSON.parse(localStorage.room)) ) {
		return false;
	}
	return true;
}
function checkUser(){
	if (isEmpty(localStorage.user)) {
		return false;//not ok
	}
	const user = JSON.parse(localStorage.user);
	if ( isEmpty(user) ) {
		return false;
	}
	if (isEmpty(user.web_user)) {
		return false;
	}
	if (typeof user.web_user.funerals_to_manage === 'undefined') {
		return false;
	}
	return true;
}

export default {load,checkRoom, checkUser};
