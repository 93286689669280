import axios from "axios";
import {
	GET_ERRORS,
	FLUSH_ERRORS,
	SET_CURRENT_ROOM,
} from "./types";
const isEmpty = require('is-empty');

export const searchCode = (obj, history=false) => dispatch => {
	axios.get(process.env.REACT_APP_API_URL+"/api/funeral/show/"+obj.code,{
		params:{
			user_id:obj.user_id
		}
	})
	.then(res => {
		// console.log(res);
		if (res.data.success) {
			dispatch(setCurrentRoom(res.data));
			dispatch({
				type: FLUSH_ERRORS,
				payload: {}
			})
			localStorage.room = JSON.stringify(res.data.funeral);
			if (history) {
				history.push("/room/phone");
			}
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: res.data
			})
		}
	})
	.catch(err =>{
		if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
			alert('La comunicación con Eternify ha fallado.');
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response
			})
		}
	});
};
export const updateRoom = (obj, history) => dispatch => {
	axios.get(process.env.REACT_APP_API_URL+"/api/funeral/show/"+obj.code,{
		params:{
			user_id:obj.user_id
		}
	})
	.then(res => {
		if (res.data.success) {
			dispatch(setCurrentRoom(res.data));
			localStorage.room = JSON.stringify(res.data.funeral);
			// history.push("/");
			if (!isEmpty(obj.reload) && obj.reload) {
				window.location.reload();
			}
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: res.data
			})
		}
	})
	.catch(err =>{
		if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
			// alert('La comunicación con Eternify ha fallado.');
			console.log('La comunicación con Eternify ha fallado.⚠');
		}else if(err.response.status !== 200){ 
			console.log('⚠',err.response);
		}else{
			// console.log(err,err.response);

			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});
};
// Set logged in user
export const setCurrentRoom = payload => {
	return {
		type: SET_CURRENT_ROOM,
		payload: payload
	};
};

// Log user out
export const leaveRoom = () => dispatch => {
	localStorage.removeItem("room");
	dispatch(setCurrentRoom({}));
};
