import { combineReducers } from "redux";
import UserReducer from "./User.reducer";
import errorReducer from "./errorReducer";
import RoomReducer from "./Room.reducer";
import AppSettings from "./AppSettings.reducer";
import AppVars from "./AppVars.reducer";

export default combineReducers({
  user: UserReducer,
  errors: errorReducer,
  room: RoomReducer,
  app_settings: AppSettings,
  app_vars: AppVars,
});