// for older browser
// import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object/assign';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// const isEmpty = require('is-empty');

// ReactDOM.render(<App />, document.getElementById('root'));

// // if (isEmpty(localStorage.app_settings)) {
// 	getAppSettings();
// // }else{
	ReactDOM.render(<App />, document.getElementById('root'));
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

// function getAppSettings() {
// 	var formData = new FormData();
// 	formData.append('url', window.location.origin);
// 	formData.append('url', 'http://myapp.com');
// 	fetch(process.env.REACT_APP_API_URL+'/api/business/app-settings', {
// 	  method: 'POST',
// 	  body: formData
// 	})
// 	.then((response) => {
// 		return response.json();
// 	})
// 	.then(json=>{
// 		// console.log(json);
// 		if (json.success === 1) {
// 			localStorage.app_settings = JSON.stringify(json.app_settings);
// 			let style = document.createElement('style');
// 			style.innerHTML = 
// 				':root{'+
//           			'--first-color:'+json.app_settings.first_color+';'+
//           			'--second-color:'+json.app_settings.second_color+';'+
//           			'--third-color:'+json.app_settings.third_color+';'+
//         		'}';
//         	document.head.appendChild(style);
// 		}else{
// 			localStorage.app_settings = '{}';
// 		}
// 		ReactDOM.render(<App />, document.getElementById('root'));

// 		// window.location.reload();
// 	})
// 	.catch(error => console.error('Error:', error))
// }

