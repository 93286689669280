import {
	SET_APP_VARS
} from "./types";

// Set app vars
export const setAppVars = payload => {
	return {
		type: SET_APP_VARS,
		payload: payload
	};
};