import {SET_APP_VARS} from "../actions/types";
const isEmpty = require('is-empty');
const initialState = {};

export default function(state = initialState, action) {
	if (isEmpty(action.payload)) {
		return state;
	}
	switch (action.type) {
		case SET_APP_VARS:
			return action.payload.app_vars;
		default:
			return state;
	}
}