import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import HomeIcon from '../../images/home.svg';
import CamIcon from '../../images/camera.svg';
import CommentIcon from '../../images/comment.svg';
import BookIcon from '../../images/album.svg';
import Share from '../others/ShareButton.component.js';
import $ from "jquery";

// const isEmpty = require('is-empty');

class NavbarFooter extends Component {
  constructor() {
    super();
    this.flowersLink = this.flowersLink.bind(this);
    this.actionButton = this.actionButton.bind(this);
  }
  flowersLink(){
      return <Link to="/room/gallery"
        onClick={()=>{
                    // window.gtag('event', 'click', {
                    //   'event_category': 'app',
                    //   'event_label': 'Botón Enviar Flores Fijo',
                    //   // 'value': <value>
                    // });
        }}
      >
        <img src={BookIcon} className="navbar-icon" alt=""/>
      </Link>;
  }
  actionButton(){
      return (<div 
                id="write"
                data-toggle="modal" 
                data-target="#custom-modal" 
                
                onClick={()=>{
                    // window.gtag('event', 'click', {
                    //   'event_category': 'app',
                    //   'event_label': 'Botón Sugerencias de Condolencias Fijo',
                    //   // 'value': <value>
                    // });
                    if (window.location.pathname !== '/room/phone') {
                      localStorage.setItem('before_action', 'write');
                      this.props.history.push('/room/phone');
                    }
                }}
              >
                <img src={CommentIcon} className="navbar-icon" alt=""/>
              </div>);
    
  }
  componentDidMount(){
    var altoVentana = 0;
    var posicionVentana = 0;
    $(window).scroll(function(event){
      var posicionScroll = $(this).scrollTop();
        if (posicionScroll > (parseInt(posicionVentana)+parseInt(altoVentana))){
          $("#navbar-footer").removeClass("navbar-footer-hidden");
        } else {
          $("#navbar-footer").addClass("navbar-footer-hidden");
        }
    });
  }
  render() {
    return (
      <div id="navbar-footer" className="fixed-bottom bg-white navbar-bottom-fixed navbar-footer navbar-footer-hidden">
          <div className="row p-2">
            <div className="col text-center">
              <Link to="/">
                <img src={HomeIcon} className="navbar-icon" alt=""/>
              </Link>
            </div>
            <div className="col text-center">
              {/*<a 
                href={Whatsapp.shareLink(this.props)}
                  onClick={(event)=>{
                    if (isEmpty(this.props.user)) {
                      event.preventDefault();
                      // window.location.pathname = "/register";
                      // this.props.history.push("/register");
                      $("#login-modal").modal("show");
                      this.props.setLoginModal("show");
                      this.props.beforeAction("share");
                    }
                    // window.gtag('event', 'click', {
                    //     'event_category': 'app',
                    //     'event_label': 'Botón Comunicar Allegados Fijo',
                    //     // 'value': <value>
                    // });
                  }}
                >
                <img src={AddContactIcon} className="navbar-icon" alt="" style={{width:'2.4rem'}}/>
              </a>*/}
              {Share.ShareButton(this.props)}
            </div>
            <div className="col text-center">
              <div
                id="write_cam"
                className="rounded-circle center-button"
                data-toggle="modal" 
                data-target="#custom-modal"  
                onClick={()=>{
                  if (window.location.pathname !== '/room/phone') {
                      localStorage.setItem('before_action', 'write_cam');
                      this.props.history.push('/room/phone');
                      return 0;
                  }
                  $("#message_file").attr('capture','environment');
                  $("label[for='message_file']").click();
                }}>
                <img src={CamIcon} alt=""/>
              </div>
            </div>
            <div className="col text-center">
            {this.actionButton()}

            </div>
            <div className="col text-center">
              {this.flowersLink()}
            </div>
          </div>
        </div>
    );
  }
}
NavbarFooter.propTypes = {
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  app_settings: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  app_settings: state.app_settings,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  {  }
)(withRouter(NavbarFooter));