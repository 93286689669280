import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";


class Icon extends Component {
	render() {
	    return(
	    	<span 
	    		className={this.props.class}
	    		onClick={this.props.onClick}
	    	/>
	    );
	}
}

Icon.propTypes = {
  app_settings: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  app_settings: state.app_settings,
});
export default connect(
  mapStateToProps,
  {}
)(Icon);