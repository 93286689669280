import { SET_CURRENT_ROOM } from "../actions/types";
const isEmpty = require('is-empty');
const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_ROOM:
			return isEmpty(action.payload)?action.payload:action.payload.funeral;
		default:
			return state;
	}
}