// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '192.168.1.17' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  // console.log(process.env.NODE_ENV, process.env.REACT_APP_NOT_SECRET_CODE);
  // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/my-service-worker.js`;
      console.log(swUrl,process.env.PUBLIC_URL);
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit http://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // askPermission();
      
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See http://bit.ly/CRA-PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
      // function requestPermission(){
      //   Notification.requestPermission().then((permission)=>{
      //     return permission;
      //   });
      // }
      // async myfunc(){

      // }
      // askPermission();
      // return registration;
    })
    // .then(registration=>{
    //   let a = 23;
      
    //   console.log('permission 0', permission);
    // })
    // .then(obj=>{
    //   console.log('my permission', Notification.permission, 'obj', obj);
    //   if (Notification.permission==='granted') {
    //     const miVapid = urlBase64ToUint8Array('BIb9KeOCz0HiF7NimveOaSzMu1NV4-xTG4P1hS7i4RcCdNxjZxkhieH4jEjbQOKInsLrkWL51WD7XFyd4tCBAR4');
    //     console.log(miVapid);
    //     const subscribeOptions = {
    //       userVisibleOnly: true,
    //       applicationServerKey: miVapid
    //     };
    //     return obj.registration.pushManager.subscribe(subscribeOptions);
    //   }
    // })
    // .then(function(pushSubscription) {
    //   console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
    //   return pushSubscription;
    // })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

// function askPermission() {
//   if(Notification.permission==='granted')
//     return 0;

//   return new Promise(function(resolve, reject) {
//     const permissionResult = Notification.requestPermission(function(result) {
//       resolve(result);
//     });

//     if (permissionResult) {
//       permissionResult.then(resolve, reject);
//     }
//   })
//   .then(function(permissionResult) {
//     if (permissionResult !== 'granted') {
//       throw new Error('We weren\'t granted permission.');
//     }
//     subscribe();
//   });
// }

// function subscribe(){
//   navigator.serviceWorker.getRegistration().then(function(reg) {
//       reg.showNotification( 'Eternify',{body:'Tus notificaciones se veran asi.'});
//       const miVapid = urlBase64ToUint8Array('BIb9KeOCz0HiF7NimveOaSzMu1NV4-xTG4P1hS7i4RcCdNxjZxkhieH4jEjbQOKInsLrkWL51WD7XFyd4tCBAR4');
//         const subscribeOptions = {
//           userVisibleOnly: true,
//           applicationServerKey: miVapid
//         };
//       return reg.pushManager.subscribe(subscribeOptions);
//   }).then(function(pushSubscription) {
//       console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
//       return pushSubscription;
//   });
// }

// function urlBase64ToUint8Array(base64String) {
//   const padding = '='.repeat((4 - base64String.length % 4) % 4);
//   const base64 = (base64String + padding)
//     .replace(/-/g, '+')
//     .replace(/_/g, '/');

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }
