import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar.component";
import Navbar from "../layout/Navbar.component";
import NavbarFooter from "../layout/NavbarFooter.component";
import LoginModal from "../auth/LoginModal.component";
import RoomHeader from "../layout/RoomHeader.component";
import FormData from 'form-data';
import axios from "axios";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { setAppVars } from "../../actions/AppVars.action";
import { setCurrentRoom } from "../../actions/Room.action";

registerLocale('es', es)

const dateFormat = require('dateformat');

const isEmpty = require('is-empty');

class InfoRoom extends Component {
  constructor() {
    super();
    this.setLoginModal = this.setLoginModal.bind(this);
    this.setBeforeAction = this.setBeforeAction.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.inputEditing = this.inputEditing.bind(this);
    this.inputStyle = this.inputStyle.bind(this);
    this.handleChangeDatetimePicker = this.handleChangeDatetimePicker.bind(this);
    this.state = {
      code: "",
      errors:"",
      login_modal:"hidden",
      before_action:null,
      room:'',
      editing_room:false,
      mass_date:'',
      mass_date_formated:'',
      editing_mass_date:false,
      mass_details:'',
      editing_mass_details:false,
      burial_date:'',
      burial_date_formated:'',
      editing_burial_date:false,
      burial_details:'',
      editing_burial_details:false,
      im_admin:false,
      sidenav:false
    };
  }
  handleChange = event => {
    if (!this.state.im_admin)
        return 0;
      const {name, value} = event.target;
      this.setState({
        [name] : value
      });
  }
  handleChangeDatetimePicker = (name,date) => {
    if (!this.state.im_admin)
        return 0;
      // console.log(name,dateFormat(date, "H'h',d,yyyy"));
      // const {name, value} = event.target;
      // console.log(date)
      if (name === "funeral_start_date") {
        this.setState({
          [name] : dateFormat(date, "yyyy-mm-dd"),
          [name+'_formated'] : dateFormat(date, "H'h',d,yyyy")
        });
      }else{
        this.setState({
          [name] : dateFormat(date, "yyyy-mm-dd HH:MM"),
          [name+'_formated'] : dateFormat(date, "H'h',d,yyyy")
        });
      }
      this.forceUpdate(()=>{
        this.onSubmit('editing_mass_date')
      });
      
  }
  inputEditing(prop){
    if (!this.state.im_admin)
        return 0;
    let state = JSON.parse('{'+
      '"editing_room":false,'+
      '"editing_mass_date":false,'+
      '"editing_mass_details":false,'+
      '"editing_burial_date":false,'+
      '"editing_burial_details":false'+
    '}');
    state[prop] = true;
    let focus = ()=>{
      let target = '';
      let elm = 'textarea';
      if(this.state.editing_room)
        target = 'room';
      if(this.state.editing_mass_details)
        target = 'mass_details';
      if(this.state.editing_burial_details)
        target = 'burial_details';
      if(this.state.editing_mass_date){
        target = 'mass_date';
        elm = 'input';
      }
      if(this.state.editing_burial_date){
        target = 'burial_date';
        elm = 'input';
      }
      if(target)
        document.querySelector(elm+'[name="'+target+'"]').focus();
    };
    this.setState(state,focus);
  }
  inputStyle(prop, element){
    // if (!this.state.im_admin)
    //     return 0;
    if (element === 'text') {
      if (this.state[prop]) {
        return JSON.parse('{"display":"none"}');
      }else{
        return JSON.parse('{"minHeight":"2rem", "marginBottom":".5rem"}');
      }
    }else{
      if (!this.state[prop]) {
        return JSON.parse('{"display":"none"}');
      }else{
        return JSON.parse('{"minHeight":"2rem", "marginBottom":".5rem"}');
      }
    }
  }
  onSubmit(prop) {
      if (!this.state.im_admin)
        return 0;
      let state = JSON.parse('{'+
        '"editing_room":false,'+
        '"editing_mass_date":false,'+
        '"editing_mass_details":false,'+
        '"editing_burial_date":false,'+
        '"editing_burial_details":false'+
      '}');
      this.setState(state);
      var formData = new FormData();

      if (this.state.image) {
        formData.append("image", this.state.image);
      }
      formData.append("funeral_id", this.props.room.id);
      formData.append("web_user_id", this.props.user.web_user.id);
      formData.append("room", this.state.room);
      // formData.append("funeral_start_date", this.state.funeral_start_date);
      // formData.append("funeral_end_date", this.state.funeral_end_date);
      if (!isEmpty(this.state.mass_date))
        formData.append("mass_date", this.state.mass_date);
      if (!isEmpty(this.state.mass_details))
        formData.append("mass_datails", this.state.mass_details);
      if (!isEmpty(this.state.burial_date))
        formData.append("burial_date", this.state.burial_date);
      if (!isEmpty(this.state.burial_details))
        formData.append("burial_datails", this.state.burial_details);
      // formData.append("is_open", this.state.is_open?1:0);
      // formData.append("funeral_parlor_id", this.props.user.funeral_parlor_admin.funeral_parlor.id);
      // formData.append("contact_email", this.state.contact_email );
      // formData.append("download_link", this.state.download_link );
      const this_ = this;
      axios.post(process.env.REACT_APP_API_URL+'/api/web-user/room/update', formData, {
            "headers": {
              "Content-Type": "application/x-www-form-urlencoded",
              'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
            },
            onUploadProgress: (event)=>{
                    const percentage = Math.round( (event.loaded * 100) / event.total );
                    // this.setState({load_percentage:percentage});
                    // console.log('load:',percentage);
                    let vars = {app_vars:{}};
                    vars.app_vars.edit_load_percentage = percentage;
                    this.props.setAppVars(vars);
                  }
      }).then(function(res){
          // console.log('SUCCESS!!',res);
          let vars = {app_vars:{}};
          vars.app_vars.edit_load_percentage = 0;
          this_.props.setAppVars(vars);

          let room = this_.props.room;
          room.name = res.data.room.name;
          room.birth_year = res.data.room.birth_year;
          room.decease_year = res.data.room.decease_year;
          room.updated_at = res.data.room.updated_at;
          // room.messages = res.data.room.messages;
          room.mass_date_formated = res.data.room.mass_date_formated;
          room.mass_date_formated_info = res.data.room.mass_date_formated_info;
          room.burial_date_formated = res.data.room.burial_date_formated;
          room.burial_date_formated_info = res.data.room.burial_date_formated_info;
          this_.props.setCurrentRoom({funeral:room});
          // alert('Actualizado');
          // window.location.pathname = "/dashboard";
          // this.props.history.push = "/dashboard"; 
      })
      .catch(function(err){
          console.log('FAILURE!!', err.response);
      });

  }
  roomAdmin(){
    if (this.state.im_admin) {
      return <div className="">
        <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_room')} style={Object.assign({top:'-53%',left:'80%'},this.inputStyle('editing_room', 'text'))}></label>
        <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_room')} style={Object.assign({top:'-53%',left:'80%'},this.inputStyle('editing_room', 'input'))}></label>
        <h5 style={this.inputStyle('editing_room', 'input')}>
                    <textarea
                      name="room"
                      className="w-100 text-secondary bg-light border-0 input-app"
                      style={{fontWeight:'inherit'}}
                      value={isEmpty(this.state.room)?'':this.state.room}
                      onChange={this.handleChange}
                      required
                    />
        </h5>
      </div>;
    }
  }
  massDateAdmin(){
    if (this.state.im_admin) {
      return <div className="">
          <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_mass_date')} style={Object.assign({top:'-53%',left:'83%'},this.inputStyle('editing_mass_date', 'text'))}></label>
          <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_mass_date')} style={Object.assign({top:'-53%',left:'83%',zIndex:'1'},this.inputStyle('editing_mass_date', 'input'))}></label>
          <h5 style={this.inputStyle('editing_mass_date', 'input')}>
            <DatePicker 
                        name="mass_date"
                          locale="es"
                          selected={!isEmpty(this.state.mass_date)?new Date(this.state.mass_date.replace(/-/g, "/")):""}
                          onChange={(date)=>{this.handleChangeDatetimePicker("mass_date",date)}}
                          className="datepicker-app"
                          placeholderText={this.state.mass_date_formated}
                          showTimeSelect
                          timeFormat="H:mm"
                          timeIntervals={10}
                          dateFormat="H'h',d,yyyy"
                          timeCaption="Hora"
                          required
                          onFocus={event=>{event.target.blur()}}
            />
          </h5>
      </div>;
    }
  }
  massDetailsAdmin(){
    if (this.state.im_admin) {
      return <div className="">
          <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_mass_details')} style={Object.assign({top:'-53%',left:'70%'},this.inputStyle('editing_mass_details', 'text'))}></label>
          <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_mass_details')} style={Object.assign({top:'-53%',left:'70%'},this.inputStyle('editing_mass_details', 'input'))}></label>
          <p style={this.inputStyle('editing_mass_details', 'input')}>
            <textarea
                        name="mass_details"
                        className="w-100 text-secondary bg-light border-0 input-app"
                        style={{fontWeight:'inherit'}}
                        value={isEmpty(this.state.mass_details)?'':this.state.mass_details}
                        onChange={this.handleChange}
                        required
                        onBlur={()=>this.onSubmit('editing_mass_details')}
            />
          </p>
      </div>;
    }
  }
  burialDateAdmin(){
    if (this.state.im_admin) {
      return <div className="">
                <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_burial_date')} style={Object.assign({top:'-53%',left:'83%'},this.inputStyle('editing_burial_date', 'text'))}></label>
                <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_burial_date')} style={Object.assign({top:'-53%',left:'83%',zIndex:'1'},this.inputStyle('editing_burial_date', 'input'))}></label>
                <h5 style={this.inputStyle('editing_burial_date', 'input')}>
                  <DatePicker 
                        name="burial_date"
                          locale="es"
                          selected={!isEmpty(this.state.burial_date)?new Date(this.state.burial_date.replace(/-/g, "/")):""}
                          onChange={(date)=>{this.handleChangeDatetimePicker("burial_date",date)}}
                          className="datepicker-app"
                          placeholderText={this.state.burial_date_formated}
                          showTimeSelect
                          timeFormat="H:mm"
                          timeIntervals={10}
                          dateFormat="H'h',d,yyyy"
                          timeCaption="Hora"
                          required
                          onFocus={event=>{event.target.blur()}}
                  />
                </h5>
            </div>;
    }
  }
  burialDetailsAdmin(){
    if (this.state.im_admin) {
      return <div className="">
                <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_burial_details')} style={Object.assign({top:'-53%',left:'70%'},this.inputStyle('editing_burial_details', 'text'))}></label>
                  <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_burial_details')} style={Object.assign({top:'-53%',left:'70%'},this.inputStyle('editing_burial_details', 'input'))}></label>
                  <p style={this.inputStyle('editing_burial_details', 'input')}>
                    <textarea
                        name="burial_details"
                        className="w-100 text-secondary bg-light border-0 input-app"
                        style={{fontWeight:'inherit'}}
                        value={isEmpty(this.state.burial_details)?'':this.state.burial_details}
                        onChange={this.handleChange}
                        required
                        onBlur={()=>this.onSubmit('editing_burial_details')}
                    />
                  </p>
              </div>;
    }
  }
  componentDidMount(){
    if (isEmpty(this.props.room)) {
      this.props.history.push('/code');
    }
    // if (isEmpty(this.props.user)) {
    //   this.props.history.push('/login');
    // }

    if (!isEmpty(localStorage.before_action)) {
      if (localStorage.before_action === "candle") {
        this.props.history.push("/room/candle");
      }else if(!isEmpty($("#"+localStorage.before_action))){
        $("#"+localStorage.before_action)[0].click();
        localStorage.removeItem('before_action');
      }
    }
    this.setState({
      room:this.props.room.room,
      mass_details:this.props.room.mass_datails,
      mass_date:this.props.room.mass_date,
      mass_date_formated:this.props.room.mass_date_formated_info,
      burial_details:this.props.room.burial_datails,
      burial_date:this.props.room.burial_date,
      burial_date_formated:this.props.room.burial_date_formated_info,
      im_admin:(!isEmpty(this.props.user))?(this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0):false
    });
  }
  setLoginModal(modal="hidden") {
    this.setState({login_modal:modal});
  }
  setBeforeAction(action=null) {
    this.setState({before_action:action});
  }
  render() {
    return (
      <div className="container"
        onClick={(event)=>{
          if(!event.target.classList.contains('open-btn') && !document.querySelector(".sidenav").contains(event.target))
            this.setState({sidenav:false,messages:this.props.room.messages});
        }}
      >
        <Sidebar 
          history={this.props.history} 
          setLoginModal={this.setLoginModal} 
          beforeAction={this.setBeforeAction}
          show={this.state.sidenav} setState={state=>this.setState(state)}
        />
        <Navbar/>
        <div className="main min-height-100">
          <div className="row">
            <div className="col-sm-12 pt-2">
              <RoomHeader/>
            </div>
          </div>
          <div className="row pb-150 pt-60">
              <div className="col-sm-12 px-0 block-list">  
                <h4 className="px-3 py-1">Ceremonia</h4>
                <div className="px-3">
                  <div className="info-p">
                    {this.massDateAdmin()}
                    <h5 
                      style={this.inputStyle('editing_mass_date', 'text')}
                      onClick={()=>this.inputEditing('editing_mass_date')}
                    >{isEmpty(this.state.mass_date_formated)?'':this.state.mass_date_formated}</h5>
                  </div>
                  <div className="info-p">
                    {this.massDetailsAdmin()}
                    <p 
                      onClick={()=>this.inputEditing('editing_mass_details')}
                      style={this.inputStyle('editing_mass_details', 'text')}
                    >{isEmpty(this.state.mass_details)?'':this.state.mass_details}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 px-0 block-list">  
                <h4 className="px-3 py-1">Convite</h4>
                <div className="px-3">
                  <div className="info-p">
                    {this.burialDateAdmin()}
                    <h5 
                      style={this.inputStyle('editing_burial_date', 'text')}
                      onClick={()=>this.inputEditing('editing_burial_date')}
                    >{isEmpty(this.state.burial_date_formated)?'':this.state.burial_date_formated}</h5>
                  </div>
                  <div className="info-p">
                    {this.burialDetailsAdmin()}
                    <p 
                      onClick={()=>this.inputEditing('editing_burial_details')}
                      style={this.inputStyle('editing_burial_details', 'text')}
                    >{isEmpty(this.state.burial_details)?'':this.state.burial_details}</p>
                  </div>
                </div>
              </div>
            </div>

            <NavbarFooter setLoginModal={this.setLoginModal} beforeAction={this.setBeforeAction}/>
        </div>
        <LoginModal setLoginModal={this.setLoginModal} modal={this.state.login_modal} beforeAction={this.setBeforeAction} action={this.state.before_action}/>
      </div>
    );
  }
}
InfoRoom.propTypes = {
  setAppVars: PropTypes.func.isRequired,
  setCurrentRoom: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  app_vars: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  errors: state.errors,
  app_vars: state.app_vars
});
export default connect(
  mapStateToProps,
  { setAppVars,setCurrentRoom }
)(InfoRoom);