import axios from "axios";

const isEmpty = require('is-empty');

function askPermission() {
  if(Notification.permission!=='default')
    return 0;
  return new Promise(function(resolve, reject) {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  })
  .then(function(permissionResult) {
    if (permissionResult !== 'granted') {
      // throw new Error('We weren\'t granted permission.');
      console.log('permission',permissionResult);
      return 0;
    }
    subscribe();
  });
}


function subscribe(){
  navigator.serviceWorker.getRegistration().then(function(reg) {
      // reg.showNotification( 'Eternify 🕊',{body:'Tus notificaciones se veran asi.'});
        const miVapid = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY);
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: miVapid
        };
      return reg.pushManager.subscribe(subscribeOptions);
  }).then(function(pushSubscription) {
      let pushNot = JSON.stringify(pushSubscription);
      // console.log('Received PushSubscription: ', pushNot);
      saveInLocal(pushNot);
      saveInServer(JSON.parse(pushNot));
      navigator.serviceWorker.getRegistration().then(function(reg) {
        let name = window.location.host.replace('.com','').replace('.es','').replace('.',' ');
        let name_arr = name.split(' ');
        name_arr.forEach((value, index)=>{
          let upper = value.charAt(0).toUpperCase() + value.slice(1);
          name = name.replace(value, upper);
        });
        reg.showNotification( name,{body:'Tus notificaciones se veran asi.'});
      })
      return pushSubscription;
  });
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function saveInLocal(pushSubscription) {
  localStorage.setItem('push_subscription',JSON.stringify(pushSubscription));
  console.log('local saved');
}

function saveInServer(pushSubscription) {
  if (isEmpty(localStorage.user)) {
    return 0;
  }

  const obj = {
    user_id:JSON.parse(localStorage.user).id,
    endpoint:pushSubscription.endpoint,
    auth:pushSubscription.keys.auth,
    p256dh:pushSubscription.keys.p256dh,
    expiration_time:pushSubscription.expirationTime
  }
  axios.post(process.env.REACT_APP_API_URL+"/api/web-user/registry/push_subscription", obj)
  .then(res => {
        if (res.data.success) {
          console.log('success', res);
          console.log('server saved');

        }else{
          console.log('fail', res);
          console.log('not server saved');
        }
  });
}
export default {askPermission, subscribe};