import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CandleIcon from '../../images/candle-fire.gif';
import WriterIcon from '../../images/writer.png';
import PictureIcon from '../../images/picture.png';
import UserIcon from '../../images/user-icon.png';
import { searchCode, updateRoom } from "../../actions/Room.action";
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';

const isEmpty = require('is-empty');

// const shuffle = function (array) {

// 	let currentIndex = array.length;
// 	let temporaryValue, randomIndex;

// 			// While there remain elements to shuffle...
// 			while (0 !== currentIndex) {
// 				// Pick a remaining element...
// 				randomIndex = Math.floor(Math.random() * currentIndex);
// 				currentIndex -= 1;

// 				// And swap it with the current element.
// 				temporaryValue = array[currentIndex];
// 				array[currentIndex] = array[randomIndex];
// 				array[randomIndex] = temporaryValue;
// 			}

// 			return array;

// };
class DesktopRoom extends Component {
	constructor() {
		super();
		this.candles = this.candles.bind(this);
		this.messages = this.messages.bind(this);
		this.textCarousel = this.textCarousel.bind(this);
		this.updateRoom = this.updateRoom.bind(this);
		this.messageCarousel = this.messageCarousel.bind(this);
		this.imageOrTitle = this.imageOrTitle.bind(this);
		this.state = {
			code:'',
			refresh_process_id:'',
			show_messages:[],
			messages_to_show:[],
			active:[]
		};
	}
	updateRoom(reload = true){
		const obj = {
	   	code:this.props.room.id, 
	   	user_id:!isEmpty(this.props.user)?this.props.user.id:null
	   };
	   this.props.updateRoom(obj, this.props.history);
	   let messages = this.props.room.messages;
		// shuffle(messages);
		this.setState({show_messages:messages});

	  //  let diff = [];
	  //  this.state.messages_to_show.forEach(message => {
	  //  		let is_in = false;
			// this.state.show_messages.forEach(show_message=>{
			// 	if (message.id == show_message.id) {
			// 		is_in = true;
			// 	}
			// });
			// if (!is_in) {
			// 	diff.push(message);
			// }
	  //  });
	  //  this.setState({show_messages:this.state.show_messages.concat(diff)});
	  //  this.forceUpdate();
	   if (reload) {
	   		window.location.reload();
	   }
  	}
  	imageOrTitle(){
	    if (isEmpty(this.props.app_settings.logo_id) || this.state.logo_error) {
	      return <div className="brand-title">{this.props.app_settings.title}</div>;
	    }else{
	      return <img style={{maxHeight:'2rem'}} src={process.env.REACT_APP_API_URL+'/api/business/'+this.props.app_settings.id+'/image'} className="img-fluid" alt="" onError={()=>{this.setState({logo_error:true})}}/>;
	    }
	}
	candles(){
		let candles = "";
		if (isEmpty(this.props.room.web_user_candles)) {
			candles = <div className="row p-4">
							<div className="col-sm-12">
								<p className="text-secondary">Aún no hay velas, se el primero en encender una.</p>
							</div>
						</div>
		}else{
			candles = this.props.room.web_user_candles.map((candle, index)=>{
				return(
					<div key={index} className="col-6">
            <figure className="text-center fig-candle">
              <img src={CandleIcon} className="img-fluid img-circle" alt="" />
              <figcaption>
                {candle.user.name}
              </figcaption>
            </figure>
          </div>
				);
			});
		}
		return candles;
	}
	messages(){
		let messages = "";
		if (isEmpty(this.props.room.messages)) {

			messages = <div className="row p-4">
										<div className="col-sm-12 col-md-6">
											<p className="text-secondary">Aún no hay condolecias, se el primero en dejar un mensaje.</p>
										</div>
									</div>
		}else{
			messages = this.props.room.messages.map((message, index)=>{
				let messageImage = null
				if(message.file_id){
					messageImage = <img className="img-fluid" src={process.env.REACT_APP_API_URL+"/api/message/"+message.id+"/file"} alt=""/>
				}
				return(
					<div key={index} className="col-sm-12 col-md-4 p-2">
						<div className="card p-3 shadow">
							<div className="card-title text-right">
								<span>{message.user.name}</span>
								<img src={!message.user.web_user.photo_id?UserIcon:process.env.REACT_APP_API_URL+"/api/web-user/"+message.user.web_user.id+"/photo"} className="img-fluid my-1 mx-2 img-circle" alt=""/>
							</div>
							<div className="card-body">
								<p>{message.text}</p>
								{messageImage}
							</div>
						</div>
					</div>
				);
			});
		}
		return messages;
	}
	textCarousel(){
			return( 
				<div id="carousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner bg-blue-et min-height-2rem">
									<div className="carousel-item active">
										<div className="carousel-caption">
											<p className="m-0">{this.props.room.funeral_parlor.name}</p>
										</div>
									</div>
									<div className="carousel-item">
										<div className="carousel-caption">
											<p className="m-0">{this.props.room.room}</p>
										</div>
									</div>
									<div className="carousel-item">
										<div className="carousel-caption">
											<p className="m-0">Misa: {this.props.room.mass_date_formated}</p>
										</div>
									</div>
									<div className="carousel-item">
										<div className="carousel-caption">
											<p className="m-0">Entierro: {this.props.room.burial_date_formated}</p>
										</div>
									</div>
					</div>
					<a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
						<span className="carousel-control-prev-icon" aria-hidden="true"></span>
						<span className="sr-only">Previous</span>
					</a>
					<a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
						<span className="carousel-control-next-icon" aria-hidden="true"></span>
						<span className="sr-only">Next</span>
					</a>
				</div>
			);

	}
	candelCarousel(){
		const itemsAmount = parseInt(this.props.room.web_user_candles.length/6);
		let items = [];
		let index = 0;
		for (var i = itemsAmount; i >= 0; i--) {
			let candles = [];
			for (var a = 5; a >= 0 ; a--) {
				if (!isEmpty(this.props.room.web_user_candles[index])) {
					let name = this.props.room.web_user_candles[index].user.name;
					if(this.props.room.web_user_candles[index].user.name.split(' ').length>1){
						name = <small>{this.props.room.web_user_candles[index].user.name}</small>
					}
					candles.push(
						<div key={index} className="col-2">
	            <figure className="text-center fig-candle">
	              <img src={CandleIcon} className="img-fluid img-circle" alt="" />
	              <figcaption className="text-white">
	                {name}
	              </figcaption>
	            </figure>
	          </div>
					);
				}
				index++;
			}
			items.push(<div key={i} className={"carousel-item "+((i === itemsAmount)?"active":'')}>
							<div className="row block-candles bg-first-color justify-content-center">
								{candles}
							</div>
						</div>);
			}
		return( 
				<div id="carousel2" className="w-100 carousel slide carousel-fade" data-ride="carousel2">
					<div className="carousel-inner">
						{items}
					</div>
				</div>
			);
	}
	messageCarousel(){
		
		// console.log("---",this.state.show_messages);
		const itemsAmount = Math.ceil(this.state.show_messages.length/6);
		let items = [];
		let index = 0;
		for (var i = itemsAmount; i >= 1; i--) {
			let messages = [];
			for (var a = 5; a >= 0 ; a--) {
				if (!isEmpty(this.state.show_messages[index])) {
					let messageImage = null
					if(this.state.show_messages[index].file_id){
						messageImage = <img className="img-fluid" src={process.env.REACT_APP_API_URL+"/api/message/"+this.state.show_messages[index].id+"/file"} alt=""/>
					}
					messages.push(
						
							<div key={index} className="card p-3 shadow" data-id={this.state.show_messages[index].id}>
								<div className="card-title">
									<img src={!this.state.show_messages[index].user.web_user.photo_id?UserIcon:process.env.REACT_APP_API_URL+"/api/web-user/"+this.state.show_messages[index].user.web_user.id+"/photo"} className="img-fluid my-1 img-circle img-message" alt=""/>
									<span>{this.state.show_messages[index].user.name}</span>
								</div>
								<div className="card-body">
									<p>{this.state.show_messages[index].text}</p>
									<p className="text-center">{messageImage}</p>
								</div>
							</div>
					);
				}
				index++;
			}
			items.push(<div key={i} className={"carousel-item "+((i === itemsAmount)?"active":'')}>
							<div className="row">
								<div className="card-columns" >
									{messages}
								</div>
							</div>
						</div>);
			}
		return( 
				<div id="carousel3" className="carousel slide carousel-fade w-100" data-ride="carousel3">
					<div className="carousel-inner pb-3">
						{items}
					</div>
				</div>
			);
	}
	
	componentDidMount(){
		const obj = {
			code: this.props.match.params.code
		};
		this.props.searchCode(obj);
		$('#carousel2').carousel({
		  interval: 15000
		});
		$('#carousel3').carousel({
		  interval: 15000
		})
		setTimeout(()=>{
			this.setState({refresh_process_id:setInterval(()=>{this.updateRoom(false)},process.env.REACT_APP_REFRESH_RATE)});
			// this.updateRoom(false);
			let messages = this.props.room.messages;
			console.log("messages:", messages);
			// shuffle(messages);
			this.setState({show_messages:messages});
			// this.setState({'messages_to_show':messages});
		},1000);
	}
	render() {
		return (
			<div className="container desktop-view min-height-100">
				<div className="custom-navbar bg-white">
        			{this.imageOrTitle()}
				</div>
				<div className="row mb-1 mt-80">
					<div className="col-sm-12 col-md-12 justify-content-center align-items-center mb-20">
						<div className="row w-100 m-0">
							<div className="col-md-7 text-center">
								<div className="block-light bg-first-color text-white">
								<img src={!this.props.room.photo_id?UserIcon:process.env.REACT_APP_API_URL+"/api/funeral/"+this.props.room.id+"/photo"} onError={(e)=>{e.target.onerror = null; e.target.src=UserIcon}} className="img-fluid img-circle room-img" alt=""/>
									<h2 className="m-0 p-0 room-title" >{this.props.room.name}</h2>
									<h3 className="room-year">{this.props.room.birth_year}-{this.props.room.decease_year}</h3>
								</div>
							</div>
							<div className="col-md-5 text-center">
								<div className="block-gray bg-second-color block-desktop-info text-white">
									<h3 className="text-center">Accede a través de <span>{window.location.host+'/code/'+this.props.room.id}</span></h3>
									<h3><img src={WriterIcon} alt=""/> Escribe una condolencia</h3>
									<h3><img src={PictureIcon} alt=""/> Envía tus imágenes</h3>
								</div>
							</div>
						</div>
					</div> 
					<div className="col-12">
						{!isEmpty(this.props.room)?this.candelCarousel():''}
					</div>
					<div className="col-sm-12 col-md-12 justify-content-center align-items-center mb-120">
						<div className="row w-100 m-0">
							<div className="col-12">
								<div className="wrapper-block-messages">
									{!isEmpty(this.state.show_messages)?this.messageCarousel():''}
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}
DesktopRoom.propTypes = {
	searchCode: PropTypes.func.isRequired,
	updateRoom: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	room: PropTypes.object.isRequired,
	app_settings: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	room: state.room,
	app_settings: state.app_settings,
	errors: state.errors
});
export default connect(
	mapStateToProps,
	{ searchCode, updateRoom }
)(DesktopRoom);