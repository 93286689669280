import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
const isEmpty = require('is-empty');

class ShowErrors extends Component {
    constructor() {
        super();
        this.errors = this.errors.bind(this);
    }
    errors(){
            let error = '';
            if (!isEmpty(this.props.errors) && !isEmpty(this.props.errors[this.props.name])) {
                error = this.props.errors[this.props.name].map((error, index)=>{
                  return <span className="d-block" key={index}>{error}</span>;
                });
            }else{
                error = <span>{!isEmpty(this.props.frontendError)?this.props.frontendError:'Este campo es requerido.'}</span>;
            }
            return error;
    }
    render() {
        return (
            <div className={(isEmpty(this.props.errors[this.props.name]))?"invalid-feedback":"invalid-feedback d-block"}>
                {this.errors()}
            </div>
        );
    }
}
ShowErrors.propTypes = {
    // searchCode: PropTypes.func.isRequired,
    // flushError: PropTypes.func.isRequired,
    // auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { }
)(ShowErrors);
