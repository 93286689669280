import React, { Component } from "react";
// Merge the object into the extended object
      // for replace Object.assign that is not suport on old safari versions
      const merge = function (obj, obj2 = {}) {
        for (var prop in obj2) {
          if (obj2.hasOwnProperty(prop)) {
            // Push each value from `obj` into `extended`
            obj[prop] = obj2[prop];
          }
        }
        return obj;
      };
class progressbar extends Component {
  constructor() {
    super();
    this.state = {
      percentage:0,
    }
  }
  
  componentDidMount(){
    this.setState({percentage:this.props.percentage});
  }
  render() {
    return (
      <div className="progress" style={this.props.percentage>0?merge({height: "10px"},this.props.style):merge({height: "0"},this.props.style)}>
        <div className="progress-bar" role="progressbar" style={{width:this.props.percentage+"%"}}></div>
      </div>
    );
  }
}
export default progressbar;