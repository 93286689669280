import axios from "axios";
import {
	GET_ERRORS,
	SET_CURRENT_USER
} from "./types";
import {setCurrentRoom} from "./Room.action";

// Register User
export const registerUser = (userData, history) => dispatch => {
	console.log("send");
	axios
		.post(process.env.REACT_APP_API_URL+"/api/web-user/registry", userData)
		.then(res =>{
			if (res.data.success) {
				console.log("received");
				dispatch(setCurrentUser(res.data));
				localStorage.user = JSON.stringify(res.data.user);
				localStorage.agreed = res.data.user.agreed_conditions;
				//due to pushnotification subscription al ask permission is needed reload instead of history push
				window.location.pathname = "/room/phone";
				// history.push("/room/phone");
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: res.data
				})
			}
		}) // re-direct to login on successful register
		// .catch(err =>{
		// 	if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
		// 		alert('La comunicación con Eternify ha fallado.');
		// 	}else{
		// 		dispatch({
		// 			type: GET_ERRORS,
		// 			payload: err.response.data
		// 		})
		// 	}
		// });
};
// Login - get user token
export const loginUser = (userData, history) => dispatch => {
	// console.log("here here");
	axios
		.post(process.env.REACT_APP_API_URL+"/api/web-user/login", userData)
		.then(res => {
			// console.log(res)

			if (res.data.success) {
				dispatch(setCurrentUser(res.data));
				localStorage.user = JSON.stringify(res.data.user);
				localStorage.agreed = res.data.user.agreed_conditions;
				//due to pushnotification subscription al ask permission is needed reload instead of history push
				window.location.pathname = "/room/phone";
				// history.push("/room/phone");
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: res.data
				});
			}
		})
		.catch(err =>{
			if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
				alert('La comunicación con Eternify ha fallado.');
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			}
		});
};

//login for link shared to web user admin trought whatsapp
export const loginByCode = (data, history) => dispatch => {
	// console.log("params",data);
	axios
		.post(process.env.REACT_APP_API_URL+"/api/web-user/login-by-code", data)
		.then(res => {
			if (res.data.success) {
				dispatch(setCurrentUser(res.data));
				localStorage.user = JSON.stringify(res.data.user);
				localStorage.agreed = res.data.user.agreed_conditions;
				dispatch(setCurrentRoom(res.data));
				localStorage.room = JSON.stringify(res.data.funeral);
				history.push("/room/phone");
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: res.data
				});
				alert("Ha ocurrido un error: #user-action-83.");
			}
		})
		.catch(err =>{
			if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
				alert('La comunicación con Eternify ha fallado.');
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
				alert("Ha ocurrido un error: #user-action-95.");
			}
		});
};
// Register User
export const signInWithPlatform = (userData, history) => dispatch => {
	axios
		.post(process.env.REACT_APP_API_URL+"/api/web-user/sign-in", userData)
		.then(res =>{
			if (res.data.success) {
				console.log("received");
				dispatch(setCurrentUser(res.data));
				localStorage.user = JSON.stringify(res.data.user);
				localStorage.agreed = res.data.user.agreed_conditions;
				// history.push("/room/phone");
				// window.location.pathname = "/room/phone";
				window.location.reload();
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: res.data
				});
				if (userData.platform === 'google') {
					window.gapi.load('auth2', function() {
			          let auth2 = window.gapi.auth2.init();
			          auth2.signOut().then(function () {
			            console.log('logout from google');
			          });
			        });
				}
			}
		}) // re-direct to login on successful register
		.catch(err =>{
			if (typeof(err.message) !== 'undefined' && err.message === "Network Error") {
				alert('La comunicación con Eternify ha fallado.');
			}else{
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			}
		});
};
// Set logged in user
export const setCurrentUser = payload => {
	return {
		type: SET_CURRENT_USER,
		payload: payload
	};
};
// Set logged in user
export const setCurrentUser_ = userData => dispatch  => {
	localStorage.user = JSON.stringify(userData.user);
	dispatch(setCurrentUser(userData));
};
// Log user out
export const logoutUser = () => dispatch => {
	localStorage.removeItem("user");
	dispatch(setCurrentUser({}));
};
