import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser, loginUser, signInWithPlatform } from "../../actions/User.action";
import closeIcon from '../../images/cancel.svg';
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';
import ShowErrors from '../errors/ShowErrors.component';
import UserIcon from '../../images/user-icon.png';
// import Logo from '../../images/eternify_logo.png';

const isEmpty = require('is-empty');

class Login extends Component {
  constructor() {
    super();
    //this.fbOnCLick = this.fbOnCLick.bind(this);
    this.imageOrTitle = this.imageOrTitle.bind(this);
    this.sectionStyles = this.sectionStyles.bind(this);
    this.storeMessage = this.storeMessage.bind(this);
    this.image = this.image.bind(this);
    this.onSubmitRegister = this.onSubmitRegister.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      section:"registry",
      image_error:false,
      max_name_len:30
    };
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    //google signin/auth api
    if (!isEmpty(this.props.action_before)) {
      this.setState({
        action_before:this.props.action_before
      });
    }
    /*let gapiLoad = (event)=>{
      if (!isEmpty(window.gapi) && isEmpty(this.props.user)) {
        let onSuccess = (googleUser) => {
          console.log("success");
            let profile = googleUser.getBasicProfile();
            const user = {
              name:profile.getName(),
              email:profile.getEmail(),
              platform:'google',
              agreed_conditions:!isEmpty(localStorage.agreed)?localStorage.agreed:'false'
            };
            this.storeMessage();
            localStorage.setItem('before_action',this.props.action);
            this.props.signInWithPlatform(user, this.props.history); 
            console.log('Logged in as: ' + googleUser.getBasicProfile().getName());
        }
        onSuccess = onSuccess.bind(this);
        let onFailure = (error) => {
            console.log(error);
        }
        window.gapi.load('auth2', function() {
                window.gapi.signin2.render(document.querySelector(".g-signin2"), {
                'scope': 'profile email',
                'onsuccess': onSuccess,
                'onfailure': onFailure
            });
        });
      }else{
        // console.log("No se pudo conectar a google. Comprueba tu conexión.");
      }
      //facebook api init
      if (!isEmpty(window.FB)) {
        window.FB.init({
          appId      : '1147536148763952',
          status     : true,
          xfbml      : true,
          version    : 'v3.3' // or v2.6, v2.5, v2.4, v2.3
        });
      }else{
        console.log('No se pudo conectar a facebook. Comprueba tu conexión.');
        // alert('No se pudo conectar a google. Comprueba tu conexión.');
      }
    };
    gapiLoad.bind(this);
    // window.addEventListener('load',gapiLoad);
    function whenAvailable(name, callback, time = 1) {
      // console.log(time);
      var interval = 10; // ms
      if (time > 10) {return 0;}
      window.setTimeout(function() {
          if (window[name]) {
              callback(window[name]);
          } else {
              window.setTimeout(()=>{whenAvailable(name, callback, time++)}, interval);
          }
      }, interval);
    }
    whenAvailable("gapi", function(t) {
        // console.log("gapi loaded");
        gapiLoad();
    });*/
  }
  /*fbOnCLick(){
    if (!isEmpty(window.FB)) {
      let this_ = this;
      window.FB.login(function(response) {
            if (response.authResponse) {
             window.FB.api('/me?fields=email,name', function(response) {
              const user = {
              name:response.name,
              email:response.email,
              platform:'facebook',
              agreed_conditions:!isEmpty(localStorage.agreed)?localStorage.agreed:'false'
            };
            localStorage.setItem('before_action',this_.props.action);
            this_.storeMessage();
            this_.props.signInWithPlatform(user, this_.props.history); 
             });
            // } else {
            //  console.log('User cancelled login or did not fully authorize.');
            }
        }, {scope: 'email,public_profile'});
    }
  }*/
  imageOrTitle(){
    if (isEmpty(this.props.app_settings.logo_id) || this.state.logo_error) {
      return <div className="brand-title">{this.props.app_settings.title}</div>;
    }else{
      return <img style={{maxHeight:'2rem'}} src={process.env.REACT_APP_API_URL+'/api/business/'+this.props.app_settings.id+'/image'} className="img-fluid" alt="" onError={()=>{this.setState({logo_error:true})}}/>;
    }
  }
  onSubmitRegister = e => {
    e.preventDefault();
    var form = document.querySelector('#modal-registry-form');
    form.classList.add('was-validated');
    if (form.checkValidity()) {
        const newUser = {
          name: this.state.name,
          // email: this.state.email,
          // password: this.state.password,
          // password2: this.state.password2,
          agreed_conditions:!isEmpty(localStorage.agreed)?localStorage.agreed:'false'
        };
        localStorage.setItem('before_action',this.props.action);
        this.storeMessage();

        this.props.registerUser(newUser, this.props.history); 
    }
  };
  onSubmitLogin = e => {
    e.preventDefault();
    var form = document.querySelector('#modal-login-form');
    form.classList.add('was-validated');
    if (form.checkValidity()) {
      const userData = {
        email: this.state.email,
        password: this.state.password,
        agreed_conditions:!isEmpty(localStorage.agreed)?localStorage.agreed:'false'
      };
      localStorage.setItem('before_action',this.props.action);
      this.storeMessage();
      this.props.loginUser(userData, this.props.history); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
    }
  };
  storeMessage(){
    if (this.props.action === 'write') {
      localStorage.setItem('before_action_text',this.props.state.text);
      localStorage.setItem('before_action_image_preview',this.props.state.message_image_preview);
      localStorage.setItem('before_action_image_user_preview',this.props.state.profile_photo_preview);
    }
  }
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  sectionStyles(elm){
    if (this.state.section === elm) {
      return JSON.parse('{}');
    }
    return JSON.parse('{"display":"none"}');
  }
  image(){
      let image = null;
        if (this.props.room.photo_id && !this.state.image_error) {
          image = process.env.REACT_APP_API_URL+'/api/parlor-admin/funerals/'+this.props.room.id+'/photo';
        }else{
          image = UserIcon;
        }
      return image;
  }


  render() {
    return (
      <div className="modal fade" id="login-modal" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:2002}}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content mx-3">
            <div className="modal-header p-2 d-block border-0">
              <div className="row">
                <div className="col-2">
                  <img src={closeIcon} className="img-fluid" style={{width:'1.5rem'}} alt="" onClick={()=>{$("#login-modal").modal("hide")}}/>
                </div>
                <div className="col-8 text-center">
                  {this.imageOrTitle()}
                </div>
                <div className="col-2">
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 text-center">
                  <img src={this.image()} className="img-fluid img-circle room-img gradient" style={{width:'7rem'}} alt="" onError={()=>{this.setState({image_error:true})}} 
                  onLoad={(event)=>{
                    event.target.classList.remove('gradient');
                  }}
                />
                </div>
                <div className="col-sm-12 text-center">

                  <h5 className="break-word my-3">
                    Necesitamos que te registres para que tu nombre aparezca junto a tus felicitaciones y foto
                  </h5>
                </div>
                <div className="col-sm-12" id="registry-section" style={this.sectionStyles('registry')}>
                    <form noValidate id="modal-registry-form" className="needs-validation" onSubmit={this.onSubmitRegister}>
                      <div className="form-group text-center">
                        <label htmlFor="name" className="font-weight-bold w-100" style={{position:'relative'}}>
                          Es muy rápido
                          <span
                            className="float-right text-secondary"
                            style={{
                              position:'absolute',
                              left:'85%'
                            }}
                          >{this.state.name.length}<small>/{this.state.max_name_len}</small></span>
                        </label>
                        <input
                          onChange={this.onChange}
                          value={this.state.name}
                          className="form-control"
                          id="name"
                          type="text"
                          required
                          maxLength={this.state.max_name_len}
                        />
                        <ShowErrors name="name" frontendError="El nombre es requerido"/>
                      </div>
                      {/*<div className="d-flex align-items-center">
                        <hr className="w-100"/>
                        <span className="m-2 text-gray">o</span>
                        <hr className="w-100"/>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-12 mb-2 d-inline-flex justify-content-center">
                            <div className="g-signin2">Conectando...</div>
                        </div>
                        <div className="col-sm-12 text-center">
                            <div 
                              className="p-1 text-white text-center btn btn-et" 
                              style={{backgroundColor:'#4267b2',borderRadius:'3px',cursor:'pointer'}}
                              onClick={()=>{this.fbOnCLick()}}
                            >
                              <span className="float-left">
                                <img height="20" width="20" src='https://static.xx.fbcdn.net/rsrc.php/v3/yM/r/vle-6xM0Vx5.png' alt=""/>
                              </span>
                              <span className="px-4" style={{fontSize:'smaller'}}>Acceder</span>
                            </div>
                        </div>
                      </div>*/}
                      <div className="form-group text-center mt-3">
                          <button type="submit" className="btn btn-lg bg-success text-white px-5 btn-et">
                            Listo
                          </button>
                      </div>
                    </form>
                  <hr/>
                  <div className="row">
                    <div className="col text-right" onClick={()=>{this.setState({section:'login'})}}>
                      <small className="text-secondary">Ya tengo cuenta,&nbsp;</small> 
                      <span className="text-info">Iniciar Sesión</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12" id="login-section" style={this.sectionStyles('login')}>
                  <form noValidate id="modal-login-form" className="needs-validation" onSubmit={this.onSubmitLogin}>
                    <div className="form-group text-center">
                      <label htmlFor="email" className="font-weight-bold m-0">Correo</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        id="email"
                        type="email"
                        className="form-control"
                        required
                      />
                      <ShowErrors name="email" frontendError="El correo es requerido"/>
                    </div>
                    <div className="form-group text-center">
                      <label htmlFor="password" className="font-weight-bold m-0">Contraseña</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.password}
                        id="password"
                        type="password"
                        className="form-control"
                        required
                        autoComplete="on"
                      />
                      <ShowErrors name="password" frontendError="La contraseña es requerida"/>
                    </div>
                    <div className="form-group text-center mt-3">
                      <button type="submit" className="btn btn-lg btn-success text-white px-5 btn-et">
                        Entrar
                      </button>
                    </div>

                  </form>
                  <hr/>
                    <div className="row">
                      <div className="col text-right" onClick={()=>{this.setState({section:'registry'})}}>
                        <small className="text-secondary">No tengo cuenta,&nbsp;</small> 
                        <span className="text-info">Registrarme</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  signInWithPlatform: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  app_settings: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  app_settings: state.app_settings,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { registerUser, loginUser, signInWithPlatform }
)(withRouter(Login));