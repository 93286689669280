import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect,Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { setCurrentUser } from "./actions/User.action";
import { setCurrentRoom } from "./actions/Room.action";
import CodeSearch from "./components/room/CodeSearch.component";
import PhoneRoom from './components/room/PhoneRoom.component';
import CandleRoom from './components/room/CandleRoom.component';
import InfoRoom from './components/room/InfoRoom.component';
import DesktopRoom from './components/room/DesktopRoom.component';
import ScrollToTop from './components/others/ScrollToTop.component';
import pushNotificationFuncts from './components/pushNotification/PushNotificationSubscribe.component';
import appSettings from './components/appSettings/AppSettings.component';
import Legal from './components/Legal/Legal.component';
import Document from './components/Legal/Document.component';
import Gallery from './components/room/Gallery.component';


const isEmpty = require('is-empty');
// Check for token to keep user logged in
if (!isEmpty(localStorage.room)) {
  const room = JSON.parse(localStorage.room);
  store.dispatch(setCurrentRoom({funeral:room}));
  if (!isEmpty(localStorage.user)) {
    const user = JSON.parse(localStorage.user);
    if (isEmpty(user.web_user)) {
      localStorage.removeItem('user');
      window.location.reload();
      // window.location.href = "/";
    }else{
      store.dispatch(setCurrentUser({user:user}));
    }
    // window.location.href = "/room/phone";
    // console.log('here')
  }else{
    // window.location.href = "/login";
  }
}

appSettings.load();
const isInPaths = (pattern) => {
      const patt = new RegExp(pattern);
      const res = patt.test(window.location.pathname);
      return res;
};
let paths = [
      /\/code/,                                // /register
      /\/legal\/document/,                                   // /login
      /\/[0-9]+/,                                  // /:code
];
if (!appSettings.checkRoom() && !paths.some(isInPaths)) {
  window.location.pathname = "/code";
}
if (!isEmpty(localStorage.user) && !appSettings.checkUser()) {
  window.localStorage.removeItem('user');
  window.location.reload();
}
class App extends Component {
  //clear local data. for solve some error caused by this. is like a app restarting
  static getDerivedStateFromError(error) {
    if (window.location.host === "app.eternify.es") {
      localStorage.clear();
      window.location.pathname = "/login";
    }
  }
  componentDidMount(){
    
    paths = [
      /\/register/,                                // /register
      /\/login/,                                   // /login
      /\/password\/recovery/,                      // /password/recovery
      /\/wuser\/[0-9]+\/password\/change\/[0-9]+/, // /wuser/:id/password/change/:code
      /\/code(\/)?([0-9]+)?/,                      // /code/:code?
      /\/legal\/document/,                      // /legal/document
      /\/[0-9]+/,                                  // /:code
    ];
    if ('Notification' in window && navigator.serviceWorker && !paths.some(isInPaths)) {
      if (!isEmpty(localStorage.user) && Notification.permission === 'default') {
          pushNotificationFuncts.askPermission();
      }
      if(!isEmpty(localStorage.user) &&  Notification.permission === 'granted' && isEmpty(localStorage.push_subscription)){
          pushNotificationFuncts.subscribe();
      }
    }
  }
  render() {
    return (
      <Provider store={store}>
        <Router>
        <ScrollToTop>
          <Legal/>
          <Route
            render={({ location }) => (
              <div className="App">
                <TransitionGroup style={{height:'100vh'}}>
                  <CSSTransition
                    key={location.key}  
                    classNames="view"
                    timeout={500}
                  >
                  <Switch location={location}>    
                    <Route exact path="/app*" render={()=>{return <Redirect to="/code"/>}}/>
                    <Route exact path="/" render={()=>{return <Redirect to="/code"/>}}/>
                    <Route exact path="/code/:code?/:login_info?" component={CodeSearch} />
                    <Route exact path="/room/phone" component={PhoneRoom} />
                    <Route exact path="/room/candle" component={CandleRoom} />
                    <Route exact path="/room/info" component={InfoRoom} />
                    <Route exact path="/room/Gallery" component={Gallery} />
                    <Route exact path="/legal/document" component={Document} />
                    <Route exact path="/:code" component={DesktopRoom}/>
                    <Route render={()=>{return <Redirect to="/code"/>}} />

                  </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            )}
          />
        </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}
export default App;