import React, {Component} from 'react';
class Document extends Component {
	render() {
	    return(
	    	<div style={{height:'100vh',overflowY:'hidden'}}>
		    	<iframe src={process.env.REACT_APP_API_URL+"/api/conditions"}
		    		title="Condiciones"
		    		height="90%"
		    		width="100%"
		    		style={{border:'0'}}
		    	></iframe>
	    		<a 
	    			className="btn btn-success fixed-bottom m-3 btn-et" 
	    			style={{ouline:'5px solid #fff'}} 
	    			href='/code'
	    		>Atrás</a>
	    	</div>
	    );
	}
}
export default Document;