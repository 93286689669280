import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar.component";
import Navbar from "../layout/Navbar.component";
import LoginModal from "../auth/LoginModal.component";
import BookModal from "../layout/BookModal.component";
import { updateRoom } from "../../actions/Room.action";
import axios from "axios";
import $ from "jquery";

const isEmpty = require('is-empty');

class Gallery extends Component {
  constructor() {
    super();
    this.setLoginModal = this.setLoginModal.bind(this);
    this.setBeforeAction = this.setBeforeAction.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.downloadImages = this.downloadImages.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.state = {
      login_modal:"hidden",
      before_action:null,
      sidenav:false,
      message:[],
      selected_messages:[],
      button_text:"Descargar"
    };
  }
  setLoginModal(modal="hidden") {
    this.setState({login_modal:modal});
  }
  setBeforeAction(action=null) {
    this.setState({before_action:action});
  }
  componentDidMount(){
    if (this.props.room.is_open === 0) {
      window.location.pathname = "/room/phone";
    }
    this.setState({
      message:this.props.room.messages
    });
    if (!isEmpty(localStorage.before_action) && localStorage.before_action === "buy" && !isEmpty($("#"+localStorage.before_action))) {
      setTimeout(()=>{
        $("#"+localStorage.before_action)[0].click();
        localStorage.removeItem('before_action');
      },500);
      
    }
  }
  setSelected(event){
    const value = event.target.value;
    let messages = null;
    if (this.state.selected_messages.indexOf(value)>=0)
    {  
      messages = this.state.selected_messages.filter((message_id,index)=>{
        return message_id !== value;
      });
    }
    else{
      messages = this.state.selected_messages;
      messages.push(value);
    }
    this.setState({
        selected_messages:messages
    });
    
  }
  isChecked(id){
    // console.log(id.toString(),this.state.selected_messages.indexOf(id.toString()),this.state.selected_messages.indexOf(id.toString())>=0?true:false);
    return this.state.selected_messages.indexOf(id.toString())>=0?true:false;
  }
  selectAll(){
    let messages = [];
    if (this.state.message.length !== this.state.selected_messages.length) {
      messages = this.state.message.map((msg,idx)=>msg.id.toString())
    }
    this.setState({
      selected_messages: messages
    });
  }
  // getBase64(url) {
  //   return axios
  //     .get(url, {
  //       responseType: 'arraybuffer'
  //     })
  //     .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  // }
  images(){
    let images = null;
    images = this.state.message.map((message, index)=>{
      if(message.file_id && message.status === 'approved'){
        return <div key={'image-'+index} id={'image-'+index} className="square">
          <input className="d-none" type="checkbox" name="messages-id[]" id={'message-'+message.id} value={message.id} checked={this.isChecked(message.id)} onChange={this.setSelected}/>
          <label htmlFor={'message-'+message.id} className="content"
              style={{
                border:'10px solid #fff',
                borderRadius: '20px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage:"url("+process.env.REACT_APP_API_URL+"/api/message/"+message.id+"/file)"
              }}
            >
          </label>
          <span  className="pin pin-check"></span>
          <img 
            className="d-none"
            src={process.env.REACT_APP_API_URL+"/api/message/"+message.id+"/file"}
            onError={()=>{
              document.querySelector('#image-'+index).classList.add("d-none");
            }}
            alt=""
          />
        </div>
      }
      return null;
    });
    return images;
  }
  downloadImages(){
    if (this.state.selected_messages.length === 0)
      return 0;
    let time = 0; 
    this.state.selected_messages.forEach((message_id,index)=>{
      // console.log(message_id);
      setTimeout(()=>{
        // window.open(process.env.REACT_APP_API_URL+"/api/message/"+message_id+"/file/down",'_blank');
        this.setState({button_text:'Descargando...'});
        axios({
          url: process.env.REACT_APP_API_URL+'/api/super-admin/funerals/'+this.props.room.id+'/messages/pdf-download', //your url
          method: 'GET',
          responseType: 'blob', // important
          params:{
            ids:[message_id]
          }
        }).then((response) => {
          this.setState({button_text:'Descargar'});
          alert('Descarga completada, encuentralo en tus descargas.')
           const url = window.URL.createObjectURL(new Blob([response.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'file'+(new Date().getTime())+'.pdf'); //or any other extension
           document.body.appendChild(link);
           link.click();
        });
      },time);
      time += 1000;
    })
    // this.setState({selected_messages:[]});
  }
  downloadPdf(){
    if (this.state.selected_messages.length === 0)
      return 0;
    axios({
      url: process.env.REACT_APP_API_URL+'/api/super-admin/funerals/'+this.props.room.id+'/messages/pdf/', //your url
      method: 'GET',
      responseType: 'blob', // important
      params:{
        ids:this.state.selected_messages
      }
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'file.pdf'); //or any other extension
       document.body.appendChild(link);
       link.click();
    });
  }
  render() {
    return (
      <div className="container"
        onClick={(event)=>{
          if(!event.target.classList.contains('open-btn') && !document.querySelector(".sidenav").contains(event.target))
            this.setState({sidenav:false,messages:this.props.room.messages});
        }}
      >
        <Sidebar 
          history={this.props.history} 
          setLoginModal={this.setLoginModal}  
          beforeAction={this.setBeforeAction}
          show={this.state.sidenav} 
          setState={state=>this.setState(state)}
        />
        <Navbar/>
        <div className="main min-height-100" style={{marginBottom:'5rem'}}>
          <div className="row mt-5">
            <div className="col-sm-12 mt-4 mb-3 text-right">
              <span onClick={this.selectAll}>seleccionar todo</span>
            </div>
            <div className="col-sm-12">
              <form id="images-form">
              <input type="hidden" name="action" value="download"/>
              <div className="row px-2">
                {this.images()}
              </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 py-3 fixed-bottom bg-white">
          <div className="row">
            <div className="col-6 pr-0">
              <div 
              className="btn btn-et w-100 h-100 align-items-center d-flex justify-content-center border mr-2"
              onClick={this.downloadImages}
              >
                {this.state.button_text}{this.state.selected_messages.length>0?' ('+this.state.selected_messages.length+')':''}
              </div>
            </div>
            <div className="col-6 pl-0">
              {/*<div 
                className="btn btn-et w-100 h-100 align-items-center d-flex justify-content-center bg-first-color text-white border ml-2"
                onClick={this.downloadPdf}
              >
                Descargar <br/> Álbum{this.state.selected_messages.length>0?' ('+this.state.selected_messages.length+')':''}
              </div>*/}
              {/*<a
                className="btn btn-et w-100 h-100 align-items-center d-flex justify-content-center bg-first-color text-white border ml-2"
                href="#!"
                onClick={e=>{
                  e.preventDefault();
                  // window.open("https://tienda.eternify.es/shop/?add-to-cart=10&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'_blank');
                  window.name = "La diversiva";
                  window.open("https://tienda.eternify.es/?billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'Tienda Eternify');
                }}
              >
                Descargar <br/> Álbum{this.state.selected_messages.length>0?' ('+this.state.selected_messages.length+')':''}
              </a>*/}
              <div
                className="btn btn-et w-100 h-100 align-items-center d-flex justify-content-center bg-first-color text-white border ml-2"
                data-target="#book-modal"
                data-toggle="modal"
                id="buy"
              >
                Descargar <br/> Libro{this.state.selected_messages.length>0?' ('+this.state.selected_messages.length+')':''}
              </div>
            </div>
          </div>
        </div>
        <LoginModal setLoginModal={this.setLoginModal} modal={this.state.login_modal} beforeAction={this.setBeforeAction} action={this.state.before_action} state={this.state}/> 
        <BookModal/>
      </div>


    );
  }
}
Gallery.propTypes = {
  updateRoom: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { updateRoom }
)(Gallery);